import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'


export const initMarqueeSlider = () => {
  var animation = { duration: 12000, easing: (t) => t }
  var slider = new KeenSlider("#RS-marquee", {
    slides: {
      perView: 6,
    },
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 10,
        }
      },
    },
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })
};
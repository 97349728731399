export const initMailchimp = (pageName) => {

  if(pageName === 'Home'){
    const planButtons = document.querySelectorAll('.RS-pricing-table__buttons .RS-button');
    
    planButtons.forEach((button) => {
      button.addEventListener('click', function() {
    
        const butonText = button.querySelector('.RS-button__content');
        const storedPlan = butonText.textContent;
        localStorage.setItem('plan', storedPlan);
      });
    });
  }


  if(pageName === 'Contact'){
    const storedPlan = localStorage.getItem('plan');
    console.log(storedPlan);

    const radioAutonomous = document.getElementById("mce-MMERGE70");
    const radioHumane = document.getElementById("mce-MMERGE71");
    const radioAugmented = document.getElementById("mce-MMERGE72");

    if (storedPlan !== null) {
      if(storedPlan.includes('Autonomous')){
        radioAutonomous.checked = true;

        radioHumane.checked = false;
        radioAugmented.checked = false;
      }
      if(storedPlan.includes('Humane')){
        radioHumane.checked = true;
        
        radioAutonomous.checked = false;
        radioAugmented.checked = false;
      }
      if(storedPlan.includes('Augmented')){
        radioAugmented.checked = true;
        
        radioAutonomous.checked = false;
        radioHumane.checked = false;
      }
    }
  

    const nameInput = document.getElementById('mce-FNAME');
    nameInput.addEventListener('blur', function(event) {
      event.preventDefault();
    
      const storedName = nameInput.value;
      localStorage.setItem('name', storedName);
    
    });


    radioAutonomous.addEventListener('click', function() {
      const storedPlan = radioAutonomous.value;
      localStorage.setItem('plan', storedPlan);
    });

    radioHumane.addEventListener('click', function() {
      const storedPlan = radioHumane.value;
      localStorage.setItem('plan', storedPlan);
    });

    radioAugmented.addEventListener('click', function() {
      const storedPlan = radioAugmented.value;
      localStorage.setItem('plan', storedPlan);
    });

  }

  if(pageName === 'ThankYou'){
    const storedName = localStorage.getItem('name');
    const nameElement = document.getElementById('RS-client-name');

    if (storedName !== null) {
      nameElement.textContent = `, ${storedName}`;
    } else {
      nameElement.textContent = '';
    }
  }

  

};


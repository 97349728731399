export const initHeroSliderControls = () => {
  const sliderNav = document.querySelector('.RS-intro__slider-nav');
  const sliderNavEls = Array.from(document.querySelectorAll('.RS-intro__slider-nav-item'));
  const sliderImages = document.querySelector('.RS-tile-slider');
  const sliderTexts = document.querySelector('.RS-intro__word-slider');
  const autoplayInterval = 5500; // 5 seconds
  
  let currentSlide = 1;
  let isAutoplayPaused = false;
  
  function showSlide(slideIndex) {
    // Add 'RS-intro__slider-nav-item--active' class to the clicked nav element
    sliderNavEls.forEach((navEl) => {
      navEl.classList.toggle('RS-intro__slider-nav-item--active', navEl.dataset.index === slideIndex.toString());
    });
  
    const visibleImageEls = Array.from(document.querySelectorAll(`[data-index="${slideIndex}"]`));
    sliderImages.querySelectorAll('.RS-intro__tile-slider-group').forEach((imageEl) => {
      const isVisible = visibleImageEls.includes(imageEl);
      imageEl.style.display = isVisible ? 'flex' : 'none';
      if (isVisible) {
        // Add a minimal delay before applying the 'is-visible' class
        setTimeout(() => {
          imageEl.classList.add('RS-is-visible');
        }, 25);
      } else {
        imageEl.classList.remove('RS-is-visible');
      }
    });
  
    const visibleTextEls = Array.from(document.querySelectorAll(`[data-index="${slideIndex}"]`));
    sliderTexts.querySelectorAll('.RS-intro__word-slider-item').forEach((textEl) => {
      const isVisible = visibleTextEls.includes(textEl);
      textEl.style.display = isVisible ? 'flex' : 'none';
      if (isVisible) {
        // Add a minimal delay before applying the 'is-visible' class
        setTimeout(() => {
          textEl.classList.add('RS-is-visible');
        }, 25);
      } else {
        textEl.classList.remove('RS-is-visible');
      }
    });
  
    currentSlide = slideIndex;
    
    // Remove all RS-button--hero-N classes from the RS-button--hero button
    const heroButton = document.querySelector('.RS-button--hero');
    heroButton.classList.remove(...Array.from({ length: 5 }, (_, i) => `RS-button--hero-${i + 1}`));

    // Add the class corresponding to the active slide
    heroButton.classList.add(`RS-button--hero-${slideIndex}`);


      // Remove all RS-intro__bg--n classes from the RS-button--hero button
      const heroBg = document.querySelector('.RS-intro__bg');
      heroBg.classList.remove(...Array.from({ length: 5 }, (_, i) => `RS-intro__bg-${i + 1}`));
  
      // Add the class corresponding to the active slide
      heroBg.classList.add(`RS-intro__bg-${slideIndex}`);
  }
  
  function handleNavClick(event) {
    const targetNavEl = event.target.closest('.RS-intro__slider-nav-item');
    if (targetNavEl) {
      showSlide(parseInt(targetNavEl.dataset.index));
    }
  }
  
  function autoplay() {
    if (!isAutoplayPaused) {
      const nextSlide = currentSlide === 5 ? 1 : currentSlide + 1;
      showSlide(nextSlide);
    }
  }
  
  function handleMouseEnter() {
    isAutoplayPaused = true;
  }
  
  function handleMouseLeave() {
    isAutoplayPaused = false;
  }
  
  sliderNav.addEventListener('click', handleNavClick);
  sliderNav.addEventListener('mouseenter', handleMouseEnter);
  sliderNav.addEventListener('mouseleave', handleMouseLeave);
  
  // Start autoplay
  setInterval(autoplay, autoplayInterval);
};